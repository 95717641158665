<template>
  <v-card>
    <v-card-title>
      {{ $t("components.CreateCohortDropdown.title") }}
    </v-card-title>
    <v-card-text>
      <v-form @submit.prevent="createCohort">
        <tenant-search-dropdown
          v-if="isAdminOrRoot || store.hasAbility(Entity.Tenant, Action.read)"
          class="mt-4"
          v-model="selectedTenant"
          data-pw="tenantSelector"
        />
        <institution-search-dropdown
          v-if="isAdminOrRoot || store.hasAbility(Entity.Institution, Action.read)"
          v-model="selectedInstitution"
          :tenant="selectedTenant"
        />
        <v-text-field
          :label="$t('common.entities.Cohort')"
          v-model="title"
          :disabled="disableTitleField"
          variant="outlined"
          :rules="titleRules"
          :loading="store.networkBusy"
          data-pw="titleField"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn-secondary
        @click="$emit('close')"
        data-pw="cancelButton"
      >
        {{ $t("buttons.cancel") }}
      </v-btn-secondary>
      <v-btn-primary
        :disabled="!canConfirm"
        @click="createCohort"
        data-pw="confirmButton"
      >
        {{ $t("buttons.confirm") }}
      </v-btn-primary>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts" setup>
import TenantSearchDropdown from "@/components/FormFields/TenantSearchDropdown.vue";
import {computed, PropType, ref, watch} from "vue";
import {isYapiError, Tenant, YapiError, Entity, Action, Role} from "@YenzaCT/sdk";
import InstitutionSearchDropdown from "@/components/FormFields/InstitutionSearchDropdown.vue";
import yapi from "@/lib/yapi";
import {useRouter} from "vue-router";
import {useGlobalStore} from "@/store";
import {useI18n} from "vue-i18n";

const {t} = useI18n();
const store = useGlobalStore();
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);

const router = useRouter();

const props = defineProps({
  tenant: {
    type: Object as PropType<Tenant | undefined>,
    required: false
  }
});

const disableInstitutionSelect = ref(true);
const disableTitleField = ref(true);
const selectedTenant = ref(props.tenant);
const selectedInstitution = ref();
const title = ref("");
const titleRules = [
  (v: string) => !!v || t("common.title_required")
];

const canConfirm = computed((): boolean =>
  !!selectedInstitution.value?.title && !!selectedInstitution.value?._id);

watch(selectedTenant, () => {
  disableInstitutionSelect.value = false;
  disableTitleField.value = true;
});

watch(selectedInstitution, () => {
  disableTitleField.value = !selectedInstitution.value;
});

async function createCohort() {
  store.networkBusy = true;
  try {
    const cohort = await yapi.admin.cohort.create(title.value, selectedInstitution.value.id);

    await router.push({
      name: "cohortDetail",
      params: {id: cohort.data._id}
    });
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
    store.networkBusy = false;
  }
}
</script>

<template>
  <AuthenticatedLayout>
    <template #headerIcon>
      far fa-building
    </template>
    <template #header>{{ $t("components.TenantTablePage.title") }}</template>

    <v-data-table-server
      class="mb-6"
      density="compact"
      :loading="store.networkBusy"
      :headers="headers"
      :items="tenants"
      :items-per-page="perPage"
      :items-length="0"
      @click:row="onClickRow"
      @update:sort-by="onSort"
      :sort-by="sort"
      :hover="true"
      hide-default-footer
    >

      <template v-slot:top>
        <v-toolbar class="data-table-toolbar">
          <search-text-field
            class="ml-2"
            :label="$t('common.search_title')"
            :disabled="store.networkBusy"
            @update:text="text => columns.title.filter = text"
          />
          <v-spacer/>
          <v-btn-primary
            v-if="store.hasAbility(Entity.Tenant, Action.create) || isAdminOrRoot"
            prepend-icon="far fa-plus"
            @click="showCreateDialog = true"
          >
            {{ $t("buttons.create") }}
          </v-btn-primary>
        </v-toolbar>
      </template>

      <template v-slot:no-data>
        <h5 class="pa-2 d-flex align-center justify-center align-items-center">
          {{ $t("tenant.no_data_message") }}
        </h5>
      </template>

      <template v-slot:loader>
        <v-sheet
          class="d-flex align-center justify-center"
          height="100%"
        >
          <v-progress-linear
            indeterminate
            color="primary"
          ></v-progress-linear>
        </v-sheet>
      </template>

      <template v-slot:item.disabled="{ item }">
        <v-icon
          v-if="!item.disabled"
          size="x-small"
        >
          far fa-close
        </v-icon>
        <v-icon v-else>
          far fa-check
        </v-icon>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon @click="openTenantDetails(item._id as string)"> far fa-pen-to-square</v-icon>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ toLocaleDateTime(item.createdAt) }}
      </template>

      <template v-slot:bottom>
        <v-divider/>
        <pagination-controls
          v-if="paginatedData"
          class="text-center pt-2"
          :pagination-footer-data="paginationFooterData"
          @update:per-page="onUpdatePerPage"
          @on-next="onNext"
          @on-prev="onPrev"
          @on-page-change="onPageChange"
          data-pw="tenantPaginationControls"
        />
      </template>
    </v-data-table-server>

    <v-dialog v-model="showCreateDialog" max-width="500">
      <create-tenant-dialog @close="showCreateDialog = false"/>
    </v-dialog>
  </AuthenticatedLayout>
</template>

<script lang="ts" setup>
import {onMounted, Ref, ref} from "vue";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {
  Action,
  Entity,
  isYapiError,
  Paginated, Role,
  Tenant,
  TenantPaginationParams,
  YapiError
} from "@YenzaCT/sdk";

import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";

import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";
import {ColumnsConfig, FooterData, footerDataFactory, usePagination} from "@/lib/pagination";
import PaginationControls from "@/components/PaginationControls.vue";
import SearchTextField from "@/components/SearchTextField.vue";
import CreateTenantDialog from "@/pages/TenantTablePage/CreateTenantDialog.vue";
import {toLocaleDateTime} from "@/lib/time";

const router = useRouter();
const store = useGlobalStore();
const {t} = useI18n();

const tenants: Ref<Tenant[]> = ref([]);
const paginatedData: Ref<Paginated<Tenant> | undefined> = ref();
let paginationFooterData: FooterData = footerDataFactory();
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);

const showCreateDialog = ref(false);

const cols: ColumnsConfig = {
  id: {
    title: t("components.TenantTablePage.table_headers.id"),
    modelKey: "_id",
    visible: false,
    filter: ""
  },
  title: {
    title: t("components.TenantTablePage.table_headers.title"),
    modelKey: "title",
    visible: true,
    filter: ""
  },
  disabled: {
    title: t("components.TenantTablePage.table_headers.disabled"),
    modelKey: "disabled",
    visible: true,
    filter: ""
  },
  createdAt: {
    title: t("components.TenantTablePage.table_headers.created_at"),
    modelKey: "createdAt",
    visible: true,
    filter: ""
  }
};

const {
  columns,
  headers,
  page,
  perPage,
  sort,
  onUpdatePerPage,
  onSort,
  onNext,
  onPrev,
  onPageChange,
  setupFooterData,
} = usePagination(fetchPage, {
  initialColumns: cols,
  initialSort: [{
    key: "createdAt",
    order: "desc"
  }]
});

async function fetchPage() {
  const params: TenantPaginationParams = {
    page: page.value,
    size: perPage.value,
    sortBy: sort.value[0].key,
    sortOrder: sort.value[0].order,
  };
  params.title = columns.title.filter as string || undefined;
  try {
    store.networkBusy = true;
    paginatedData.value = (await yapi.admin.tenant.paginate(params)).data;
    tenants.value = paginatedData.value.docs;
    paginationFooterData = setupFooterData(paginatedData.value);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

onMounted(async () => {
  await fetchPage();
});

const onClickRow = async (event: Event, row: { internalItem: { raw?: Tenant } }) => {
  try {
    if (row.internalItem && row.internalItem.raw) {
      const tenantId = row.internalItem.raw._id;
      if (tenantId) {
        await openTenantDetails(tenantId);
      }
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  }
};

const openTenantDetails = async (id: string) => {
  await router.push({
    name: "tenantDetail",
    params: {id}
  });
};
</script>

<template>
  <v-card max-width="500px" class="ma-auto">
    <v-card-title class="d-flex justify-space-between align-center">
      {{ $t("components.UserDetailPage.menu.reset_assessments") }}

      <v-btn
        icon="fa fa-times"
        variant="text"
        @click="closeAndReload"
      ></v-btn>
    </v-card-title>
    <v-divider/>
    <v-card-text>
      <p class="mt-2 mb-2 fa-primary" data-cy="dialog-title">{{ $t("components.ResetAssessmentDialog.title") }}</p>

      <v-row class="checkbox-row" data-cy="checkbox-row">
        <v-col cols="6">
          <v-checkbox
            label="Select All"
            v-model="selectAll"
            color="primary"
            class="font-weight-bold"
            density="compact"
            :hide-details="true"
            @change="selectAllAssessments"
            data-cy="select-all-checkbox"
          />
          <v-checkbox
            v-for="(value, key) in selectedItems"
            :key="key"
            :label="$t(`assessments.${key}.title`)"
            :value="true"
            color="primary"
            v-model="selectedItems[key]"
            :hide-details="true"
            density="compact"
            class="custom-checkbox"
            data-cy="checkbox-item"
          />
        </v-col>
      </v-row>

      <v-card class="bg-red-lighten-5 rounded border mt-3" variant="outlined">
        <v-card-text>
          <div class="mb-2">
            <v-icon class="mr-2" color="red" size="small">fa-solid fa-exclamation-circle</v-icon>
            <span class="text-red font-weight-bold">
              {{ $t("components.ResetAssessmentDialog.danger_zone") }}
            </span> <span>|
              {{ $t("components.ResetAssessmentDialog.action_label") }}</span>
          </div>

          <v-text-field
            v-model="confirmText"
            density="compact"
            variant="outlined"
            :hide-details="true"
            class="mb-2"
            data-cy="confirm-text-field"
          />
          <span>{{ $t('common.type_confirm_message') }}</span>

        </v-card-text>
      </v-card>
    </v-card-text>

    <v-divider class="mt-2"></v-divider>

    <v-card-actions class="my-2 d-flex justify-end">
      <v-btn-secondary
        @click="closeAndReload"
        data-cy="cancel-button"
      >
        {{ $t("buttons.cancel") }}
      </v-btn-secondary>
      <v-btn-delete
        @click="handleSubmit"
        :disabled="!hasSelectedAssessment"
        data-cy="submit-button"
        variant="flat"
      >
        {{ $t("buttons.reset") }}
      </v-btn-delete>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {computed, ref, PropType, Ref, watch, reactive} from "vue";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import {AssessmentType, isYapiError, YapiError} from "@YenzaCT/sdk";

const store = useGlobalStore();

const props = defineProps({
  id: {
    type: String as PropType<string>,
    required: true
  },
  type: {
    type: String as PropType<"user" | "cohort">,
    required: true
  }
});

const id: Ref<string> = ref(props.id);
const error = ref<string | undefined>("");
const selectAll = ref(false);
const confirmText = ref("");

const emits = defineEmits<{
  (e: "close"): void;
}
>();

const selectedItems = reactive<{ [key: string]: boolean }>({
  [AssessmentType.HollandCode]: false,
  [AssessmentType.Big5]: false,
  [AssessmentType.LearningStyles]: false,
  [AssessmentType.ManagingEmotion]: false,
  [AssessmentType.PerceivingEmotion]: false,
  [AssessmentType.UnderstandingEmotion]: false,
  [AssessmentType.Maze]: false,
  [AssessmentType.RockPaperScissors]: false,
});

const hasSelectedAssessment = computed(() =>
  Object.values(selectedItems).some((value) => value) && confirmText.value === "CONFIRM");

function selectAllAssessments() {
  if (selectAll.value) {
    for (const key in selectedItems) {
      selectedItems[key] = true;
    }
  } else {
    for (const key in selectedItems) {
      selectedItems[key] = false;
    }
  }
}

function closeAndReload() {
  emits("close");
}

async function handleSubmit() {
  const selectedAssessments = Object.keys(selectedItems).filter(
    (key) => selectedItems[key]
  );
  try {
    store.networkBusy = true;

    if (props.type === "user") {
      if (selectAll.value) {
        await yapi.admin.user.resetAssessments(id.value);
      } else {
        await yapi.admin.user.resetSelectedAssessments(id.value, selectedAssessments);
      }
    } else if (props.type === "cohort") {
      await yapi.admin.cohort.resetAssessments(id.value, selectedAssessments);
    }

    emits("close");
    store.showSnackbar("The requested assessment has been reset.");
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
      error.value = yError.response?.data.message;
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }

  // Watch for changes in individual checkboxes, and if any checkbox is unchecked, uncheck the "Select All" checkbox.
  watch(selectedItems, (newVal) => {
    selectAll.value = Object.values(newVal).every((value) => value);
  });
}
</script>

<template>
  <div>
    <v-main>
      <v-app-bar flat class="v-toolbar__content px-4">
        <v-app-bar-title class="d-flex align-center" @click="goToHome">
          <img :src="store.appConfiguration.images.logo" :alt="store.appConfiguration.appName" height="35" class="mr-3">
        </v-app-bar-title>

        <v-btn color="primary" :to="{name: 'dashboard'}">
          <v-icon start>far fa-gauge</v-icon>
          {{ $t('components.MainNavigation.dashboard') }}
        </v-btn>

        <v-btn color="primary" v-if="isAdminOrRoot || store.hasSomeAbilities(Entity.User)"
               :to="{name: 'users'}">
          <v-icon start>far fa-users</v-icon>
          {{ $t('components.MainNavigation.users') }}
        </v-btn>

        <v-btn
          color="primary"
          v-if="isAdminOrRoot || store.hasSomeAbilities(Entity.Tenant)"
          :to="{name: 'vouchers'}"
        >
          <v-icon start>far fa-ticket</v-icon>
          {{ $t('components.MainNavigation.vouchers') }}
        </v-btn>

        <v-btn color="primary" :to="{ name: 'reports' }">
          <v-icon start>far fa-chart-mixed</v-icon>
          {{ $t('components.MainNavigation.insights') }}
        </v-btn>

        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn color="primary" v-bind="props" class="text-none custom-tab-btn no-hover" variant="text">
              <v-icon start>far fa-users</v-icon>
              {{ $t('components.MainNavigation.manage') }}
            </v-btn>
          </template>
          <v-list class="custom-list">
            <v-list-item
              v-if="isAdminOrRoot || store.hasSomeAbilities(Entity.Tenant)"
              :to="{name: 'tenants'}"
              prepend-icon="far fa-globe"
            >
              <v-list-item-title>{{ $t('components.MainNavigation.tenants') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="isAdminOrRoot || store.hasSomeAbilities(Entity.Institution)"
              :to="{name: 'institutions'}"
              prepend-icon="far fa-school"
            >
              <v-list-item-title>{{ $t('components.MainNavigation.institutions') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="isAdminOrRoot || store.hasSomeAbilities(Entity.Cohort)"
              :to="{name: 'cohorts'}"
              prepend-icon="far fa-graduation-cap"
            >
              <v-list-item-title>{{ $t('components.MainNavigation.cohorts') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="isAdminOrRoot || store.hasSomeAbilities(Entity.Coupon)"
              :to="{name: 'coupons'}"
              prepend-icon="far fa-badge-percent"
            >
              <v-list-item-title>{{ $t('components.MainNavigation.coupons') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-spacer></v-spacer>

        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="bg-grey-lighten-4 border border-grey-lighten-4 text-grey-darken-1"
              rounded="lg"
              size="large"
            >
              <v-icon
                color="grey-darken-1"
                size="small"
              >
                far fa-gear
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="isAdminOrRoot"
              :to="{name: 'configuration'}"
              prepend-icon="far fa-puzzle-piece"
            >
              <v-list-item-title>{{ $t('components.MainNavigation.configuration') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="isAdminOrRoot"
              :to="{name: 'userSettings'}"
              prepend-icon="far fa-gears"
            >
              <v-list-item-title>{{ $t('components.MainNavigation.account') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="logout"
              prepend-icon="far fa-sign-out"
            >
              <v-list-item-title>{{ $t('components.MainNavigation.logout') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-container fluid class="mt-2">
        <v-row align-content="center" :dense="true" :no-gutters="true">
          <v-col align-self="center">
            <v-col>
              <slot name="headerNavigate"/>
            </v-col>
            <v-icon size="small" color="primary" class="mr-2 ml-8 mt-n1">
              <slot name="headerIcon"/>
            </v-icon>
            <span class="text-h6 font-weight-bold ml-2">
              <slot name="header"/>
            </span>
          </v-col>
          <v-col class="d-flex flex-row justify-end">
            <slot name="context-menu"/>
          </v-col>
          <v-divider class="mt-2 mx-8"/>
        </v-row>
        <v-row :dense="true" :no-gutters="true">
          <v-col cols="12">
            <slot name="breadcrumbs"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <slot/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script setup lang="ts">
import {useGlobalStore} from "@/store";
import {computed, ref, watch} from "vue";
import {Entity, Role} from "@YenzaCT/sdk";
import {useRoute, useRouter} from "vue-router";

const store = useGlobalStore();
const route = useRoute();
const router = useRouter();
const logout = async () => {
  await store.logout();
};
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);

const activeTab = ref<string | null>(null);

const isManageActive = computed(() => {
  const manageRoutes = ["tenants", "institutions", "cohorts", "coupons"];
  return manageRoutes.includes(route.name as string);
});

function goToHome() {
  router.push({name: "dashboard"});
}

// Watch for route changes to update activeTab
watch(
  () => route.name,
  (newRouteName) => {
    if (isManageActive.value) {
      activeTab.value = "manage";
    } else {
      activeTab.value = newRouteName as string | null;
    }
  },
  {immediate: true}
);

</script>

<style scoped>
.v-toolbar__content {
  margin-bottom: -1px;
}

.custom-tab-btn :deep(.v-btn__overlay, .v-btn--active) {
  opacity: 0;
}

.no-hover:hover {
  background-color: transparent !important;
}
</style>

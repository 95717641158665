<template>
  <AuthenticatedLayout>
    <template #headerIcon>
      far fa-building
    </template>
    <template #header>{{ $t("components.InstitutionTablePage.title") }}</template>

    <v-data-table-server
      class="mb-6"
      density="compact"
      :loading="store.networkBusy"
      :headers="headers"
      :items="institutions"
      :items-per-page="perPage"
      :items-length="0"
      @click:row="onClickRow"
      @update:sort-by="onSort"
      :sort-by="sort"
      :hover="true"
      hide-default-footer
    >
      <template v-slot:no-data>
        <h5 class="pa-2 d-flex align-center justify-center align-items-center">
          {{ $t("common.no_data_message") }}
        </h5>
      </template>
      <template v-slot:loader>
        <v-sheet
          class="d-flex align-center justify-center"
          height="100%"
        >
          <v-progress-linear
            indeterminate
            color="primary"
          ></v-progress-linear>
        </v-sheet>
      </template>

      <template v-slot:top>
        <v-toolbar class="data-table-toolbar">
          <search-text-field
            class="ml-2"
            :label="$t('common.search_title')"
            :disabled="store.networkBusy"
            @update:text="text => columns.title.filter = text"
          />
          <v-spacer/>
          <toolbar-menu
            :label="$t('buttons.filter')"
            :active-filter-count="activeFilterCount"
            icon="far fa-filter"
            @resetFilter="resetFilter"
          >
            <toolbar-menu-list-item-menu
              :label="columns.tenant.title"
              :is-filter-active="!!columns.tenant.filter"
            >
              <tenant-search-dropdown data-pw="tenantFilter"  v-model="selectedTenant" />
            </toolbar-menu-list-item-menu>
          </toolbar-menu>
          <v-btn-primary
            v-if="isAdminOrRoot"
            prepend-icon="far fa-plus"
            @click="showCreateInstitution = true"
            data-pw="createInstitutionButton"
          >
            {{ $t("buttons.create") }}
          </v-btn-primary>
        </v-toolbar>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ toLocaleDateTime(item["createdAt"]) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon @click="openInstitutionDetails(item._id as string)">
          far fa-pen-to-square
        </v-icon>
      </template>

      <template v-slot:bottom>
        <v-divider/>
        <pagination-controls
          v-if="paginatedData"
          class="text-center pt-2"
          :pagination-footer-data="paginationFooterData"
          @update:per-page="onUpdatePerPage"
          @on-next="onNext"
          @on-prev="onPrev"
          @on-page-change="onPageChange"
          data-pw="institutionPaginationControls"
        />
      </template>
    </v-data-table-server>

    <v-dialog v-model="showCreateInstitution" persistent max-width="500">
      <create-institution-dialog
        @close="onCloseCreateDialog"
        :title="t('components.InstitutionTablePage.create_institution')"
      />
    </v-dialog>
  </AuthenticatedLayout>
</template>

<script lang="ts" setup>
import {onMounted, Ref, ref, watch} from "vue";
import yapi from "@/lib/yapi";
import {useI18n} from "vue-i18n";
import {useRouter, useRoute} from "vue-router";
import {
  Institution,
  InstitutionPaginationParams,
  isYapiError,
  Paginated,
  Role, Tenant,
  YapiError
} from "@YenzaCT/sdk";

import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";
import PaginationControls from "@/components/PaginationControls.vue";
import SearchTextField from "@/components/SearchTextField.vue";

import {useGlobalStore} from "@/store";
import CreateInstitutionDialog from "@/components/Institutions/CreateInstitutionDialog.vue";
import {ColumnsConfig, FooterData, footerDataFactory, usePagination} from "@/lib/pagination";
import ToolbarMenuListItemMenu from "@/components/DataTable/ToolbarMenuListItemMenu.vue";
import ToolbarMenu from "@/components/DataTable/ToolbarMenu.vue";
import {toLocaleDateTime} from "@/lib/time";
import TenantSearchDropdown from "@/components/FormFields/TenantSearchDropdown.vue";

const store = useGlobalStore();
const router = useRouter();
const route = useRoute();

const {t} = useI18n();

const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);
const showCreateInstitution = ref(false);
const institutions: Ref<Institution[]> = ref([]);
const paginatedData: Ref<Paginated<Institution> | undefined> = ref();
let paginationFooterData: FooterData = footerDataFactory();

const cols: ColumnsConfig = {
  id: {
    title: t("common._id"),
    modelKey: "_id",
    visible: false,
    filter: ""
  },
  title: {
    title: t("common.title"),
    modelKey: "title",
    visible: true,
    filter: ""
  },
  tenant: {
    title: t("common.entities.Tenant"),
    modelKey: "tenant.title",
    visible: true,
    filter: ""
  },
  createdAt: {
    title: t("common.created_at"),
    modelKey: "createdAt",
    visible: true,
    filter: ""
  }
};

const {
  columns,
  headers,
  page,
  perPage,
  sort,
  activeFilterCount,
  selectedTenant,
  setupTenantFilters,
  resetFilter,
  createUrlFilterParameters,
  onUpdatePerPage,
  onSort,
  onNext,
  onPrev,
  onPageChange,
  setupFooterData,
} = usePagination(fetchPage, {
  initialColumns: cols,
  initialSort: [{
    key: "createdAt",
    order: "asc"
  }]
});

watch(() => selectedTenant.value, (newTenant: Tenant | undefined) => {
  columns.tenant.filter = "";
  columns.tenant.filterText = "";

  if (newTenant && newTenant._id) {
    columns.tenant.filter = newTenant._id;
    columns.tenant.filterText = newTenant.title;
  }
});

onMounted(async () => {
  setupParams();
  await setupTenantFilters(
    columns.tenant.filter as string,
    "",
    "",
  );
  await fetchPage();
});

async function fetchPage() {
  const params: InstitutionPaginationParams = {
    page: page.value,
    size: perPage.value,
    sortBy: sort.value[0].key,
    sortOrder: sort.value[0].order,

    title: columns.title.filter as string || undefined,
    filterTenant: columns.tenant.filter as string || undefined,
  };

  try {
    store.networkBusy = true;
    paginatedData.value = (await yapi.admin.institution.paginate(params)).data;

    await router.push({query: createUrlFilterParameters(params)});

    if (paginatedData.value)
      institutions.value = paginatedData.value.docs;
    paginationFooterData = setupFooterData(paginatedData.value);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

function setupParams() {
  page.value = route.query.page ? parseInt(route.query.page as string) : page.value;
  perPage.value = route.query.size ? parseInt(route.query.size as string) : perPage.value;
  sort.value[0].key = route.query.sortBy as string || sort.value[0].key;
  sort.value[0].order = route.query.sortOrder as "asc" | "desc" || sort.value[0].order;

  columns.title.filter = route.query.title as string || columns.title.filter;
  columns.tenant.filter = route.query.filterTenant as string || columns.tenant.filter;
}

async function onCloseCreateDialog() {
  showCreateInstitution.value = false;
  await fetchPage();
}

const onClickRow = async (event: Event, row: { internalItem: { raw?: Institution } }) => {
  try {
    if (row.internalItem && row.internalItem.raw) {
      const institutionId = row.internalItem.raw._id;
      if (institutionId) {
        await openInstitutionDetails(institutionId);
      }
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  }
};

const openInstitutionDetails = async (id: string) => {
  await router.push({
    name: "institutionDetail",
    params: {id}
  });
};
</script>

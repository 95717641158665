<template>
  <v-card>
    <v-card-title>{{ $t("components.CreateTenantDialog.title") }}</v-card-title>
    <v-card-text>
      <v-form @submit.prevent @submit="createTenant">
        <v-text-field
          :label="$t('common.title')"
          v-model="title"
          variant="outlined"/>
        <v-select
          :label="$t('common.country')"
          v-model="country"
          :items="countryOptions"
          variant="outlined"/>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn-secondary @click="$emit('close')">
        {{ $t('buttons.cancel') }}
      </v-btn-secondary>
      <v-btn-primary
        @click="createTenant"
        type="submit"
        :disabled="!valid"
      >
        {{ $t('buttons.create')}}
      </v-btn-primary>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {computed, ref} from "vue";
import yapi from "@/lib/yapi";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {countryOptions} from "@/lib/appConfiguration";
import {CountryCode, isYapiError, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
const router = useRouter();

const {t} = useI18n();
const store = useGlobalStore();
const title = ref("");
const country = ref<CountryCode | null>(null);

const valid = computed(() => !!title.value && !!country.value);
async function createTenant() {
  try {
    const tenant = await yapi.admin.tenant.create(title.value, country.value as CountryCode);
    await router.push({
      name: "tenantDetail",
      params: {id: tenant.data._id}
    });
    store.showSnackbar(t("components.CreateTenantDialog.created_message"));
  } catch (e) {
    if (isYapiError(e)) {
      const error = e as YapiError;
      store.showSnackbar(error.message);
    } else {
      store.showSnackbar("An error occurred while saving the countries");
    }
  }
}
</script>

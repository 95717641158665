<template>
  <v-card>
    <v-card-text>
      <v-form @submit.prevent @submit="createInstitution">
        <tenant-search-dropdown
          class="mt-4"
          v-model="selectedTenant"
          :disabled="disableTenantSelect"
          data-pw="tenantSelector"
        />
        <v-text-field
          :label="$t('common.title')"
          v-model="title"
          variant="outlined"
          :rules="titleRules"
          data-pw="titleField"
        />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn-secondary
        @click="$emit('close')"
        data-pw="cancelButton"
      >
        {{ $t("buttons.cancel") }}
      </v-btn-secondary>
      <v-btn-primary
        :disabled="!canConfirm"
        @click="createInstitution"
        data-pw="confirmButton"
      >
        {{ $t("buttons.confirm") }}
      </v-btn-primary>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {Tenant} from "@YenzaCT/sdk";
import {computed, onMounted, PropType, ref} from "vue";
import {useRouter} from "vue-router";
import yapi from "@/lib/yapi";
import TenantSearchDropdown from "@/components/FormFields/TenantSearchDropdown.vue";
import {useI18n} from "vue-i18n";

const {t} = useI18n();
const router = useRouter();

const props = defineProps({
  tenant: {
    type: Object as PropType<Tenant | undefined>,
    required: false
  }
});

const title = ref("");
const disableTenantSelect = ref(false);
const selectedTenant = ref<Tenant | undefined>();
const titleRules = [
  (v: string) => !!v || t("common.title_required")
];

onMounted(async () => {
  if (props.tenant !== undefined) {
    selectedTenant.value = props.tenant;
    disableTenantSelect.value = true;
  }
});

/**
 * Checks if form is valid and activates confirm button
 */
const canConfirm = computed((): boolean =>
  !!selectedTenant.value?.title && !!selectedTenant.value?._id);

/**
 * Creates an institution with the given title and tenant id
 */
async function createInstitution() {
  if (selectedTenant.value && selectedTenant.value._id) {
    const res = await yapi.admin.institution.create(title.value, selectedTenant.value._id);

    await router.push({
      name: "institutionDetail",
      params: {id: res.data._id}
    });
  }
}
</script>

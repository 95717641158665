<template>
  <v-menu
    bottom
    transition="scale-transition"
  >
    <template v-slot:activator="{ props }">
      <v-btn-secondary
        v-bind="props"
        data-pw="accountSettingsButton"
        class="mt-2 mb-2"
      >
        <v-icon class="mr-2" left>far fa-gear</v-icon>
        {{ $t("buttons.account_settings") }}
      </v-btn-secondary>
    </template>
    <v-list>
      <v-list-item
        :disabled="!isAdminOrRoot && !canEditAnyUsers"
        @click="showResetPasswordDialog = true"
        data-pw="resetPasswordDialogButton"
        prepend-icon="fa-light fa-key"
      >
        {{ $t("components.UserDetailPage.menu.reset_password") }}
      </v-list-item>
      <v-list-item
        :disabled="!isAdminOrRoot && !canEditAnyUsers"
        @click="showChangeEmailDialog = true"
        data-pw="changeEmailDialogButton"
        prepend-icon="fa-light fa-envelope"
      >
        {{ $t("components.UserDetailPage.menu.change_email") }}
      </v-list-item>
      <v-list-item
        :disabled="!isAdminOrRoot && !canEditAnyUsers"
        @click="showChangePhoneDialog = true"
        data-pw="changePhoneDialogButton"
        prepend-icon="fa-light fa-phone"
      >
        {{ $t("components.UserDetailPage.menu.change_phone_number") }}
      </v-list-item>
      <v-list-item
        v-if="isAdminOrRoot"
        @click="showChangeAccountTypeDialog = true"
        prepend-icon="fa-light fa-user-cog"
      >
        {{ $t("components.UserDetailPage.menu.change_account_type") }}
      </v-list-item>
      <v-list-item
        v-if="isAdminOrRoot"
        @click="showChangeRoleDialog = true"
        prepend-icon="fa-regular fa-circle-user"
      >
        {{ $t("components.UserDetailPage.menu.change_role") }}
      </v-list-item>
      <v-divider />
      <v-list-item
        v-if="isAdminOrRoot"
        @click="showResetAssessmentsDialog = true"
        data-pw="resetAssessmentsDialogButton"
        prepend-icon="fa-light fa-undo"
      >
        {{ $t("components.UserDetailPage.menu.reset_assessments") }}
      </v-list-item>
      <v-divider v-if="isAdminOrRoot" />
      <v-list-item
        v-if="isAdminOrRoot"
        @click="showDisableEnableUser = true"
        data-pw="disableEnableUserDialogButton"
        prepend-icon="fa-light fa-user-slash"
      >
        <p style="color:red;">
          {{
            user?.auth.accountDisabled
              ? $t("components.UserDetailPage.menu.enable")
              : $t("components.UserDetailPage.menu.disable")
          }}
        </p>
      </v-list-item>
    </v-list>
  </v-menu>
  <v-dialog v-model="showChangeEmailDialog" persistent max-width="500">
    <change-email-address-dialog
      @close="onCloseChangeEmailDialog"
      :user="user"
    />
  </v-dialog>
  <v-dialog v-model="showChangePhoneDialog" persistent max-width="500">
    <change-phone-dialog
      @close="onCloseChangePhoneDialog"
      :user="user"
    />
  </v-dialog>
  <v-dialog v-model="showChangeAccountTypeDialog" persistent max-width="500">
    <change-account-type-dialog
      @close="onCloseChangeAccountTypeDialog"
      :user="user"
    />
  </v-dialog>
  <v-dialog v-model="showDisableEnableUser" persistent max-width="500">
    <enable-disable-dialog
      @close="onDisableEnableDialog"
      :user="user"
    />
  </v-dialog>
  <v-dialog v-model="showResetAssessmentsDialog" persistent max-width="1000">
    <reset-assessments
      @close="onCloseResetAssessmentDialog"
      :id="user._id!"
      type="user"
    />
  </v-dialog>
  <v-dialog v-model="showResetPasswordDialog" persistent max-width="500">
    <reset-password-dialog
      :full-name="fullName"
      :user="user"
      @close="showResetPasswordDialog = false"
    />
  </v-dialog>
  <v-dialog v-model="showChangeRoleDialog" persistent max-width="500">
    <update-user-role
      :user="user"
      :roleUpdate="user.app.role"
      @close="onCloseChangeRoleDialog"
    />
  </v-dialog>
</template>

<script setup lang="ts">
import ChangeAccountTypeDialog from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/ChangeAccountTypeCard.vue";
import ResetPasswordDialog from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/ResetPasswordForm.vue";
import ResetAssessments from "@/components/ResetAssessmentDialog.vue";
import UpdateUserRole from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/ChangeUserRoleForm.vue";
import ChangeEmailAddressDialog from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/ChangeEmailAddressForm.vue";
import ChangePhoneDialog from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/ChangePhoneForm.vue";
import EnableDisableDialog from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/DisableEnableCard.vue";
import {computed, PropType, ref} from "vue";
import {DashUser} from "@YenzaCT/sdk";

const showDisableEnableUser = ref(false);
const showResetAssessmentsDialog = ref(false);
const showChangeEmailDialog = ref(false);
const showChangePhoneDialog = ref(false);
const showChangeAccountTypeDialog = ref(false);
const showResetPasswordDialog = ref(false);
const showChangeRoleDialog = ref(false);

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  },
  isAdminOrRoot: {
    type: Boolean,
    required: true
  },
  canEditAnyUsers: {
    type: Boolean,
    required: true
  }
});

const emit = defineEmits(["updateUser"]);

const fullName = computed(() => {
  if (!props.user) return "";
  return `${props.user.profile.firstName} ${props.user.profile.lastName}`;
});

async function onCloseChangeEmailDialog() {
  showChangeEmailDialog.value = false;
  const success = true;
  emit("updateUser", success);
}

async function onDisableEnableDialog() {
  showDisableEnableUser.value = false;
  const success = true;
  emit("updateUser", success);
}

const onCloseResetAssessmentDialog = async () => {
  showResetAssessmentsDialog.value = false;
  const success = true;
  emit("updateUser", success);
};

const onCloseChangePhoneDialog = () => {
  showChangePhoneDialog.value = false;
  const success = true;
  emit("updateUser", success);
};

const onCloseChangeAccountTypeDialog = () => {
  showChangeAccountTypeDialog.value = false;
  const success = true;
  emit("updateUser", success);
};

const onCloseChangeRoleDialog = () => {
  showChangeRoleDialog.value = false;
  const success = true;
  emit("updateUser", success);
};

</script>

<template>
  <h3>{{ $t("common.overview") }}</h3>
  <v-row>
    <v-col>
      <info-card
        :title="DCMainTitle"
        :left_key="DCSecondaryTraitTitle"
        :body-left="DCSecondaryTraitBody"
        :right_key="DCPrimaryTraitTitle"
        :body-right="DCPrimaryTraitBody"
        class="my-4"/>
      <info-card
        :title="OWMainTitle"
        :left_key="OWPrimaryTraitTitle"
        :body-left="OWPrimaryTraitBody"
        :right_key="OWSecondaryTraitTitle"
        :body-right="OWSecondaryTraitBody"
        class="my-4"
      />
      <info-card
        :title="DTMainTitle"
        :left_key="DTPrimaryTraitTitle"
        :body-left="DTPrimaryTraitBody"
        :right_key="DTSecondaryTraitTitle"
        :body-right="DTSecondaryTraitBody"
        class="my-4"
      />
      <info-card
        :title="SFMainTitle"
        :left_key="SFPrimaryTraitTitle"
        :body-left="SFPrimaryTraitBody"
        :right_key="SFSecondaryTraitTitle"
        :body-right="SFSecondaryTraitBody"
        class="my-4"
      />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import InfoCard from "@/pages/UserDetailPage/components/profile/AssessmentReportsCard/LearningStyleReport/InfoCard.vue";
import {LearningStylesReportContent} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import {ref, PropType} from "vue";
import {useI18n} from "vue-i18n";

const store = useGlobalStore();
const {t} = useI18n();

const props = defineProps({
  reportContent: {
    type: Object as PropType<LearningStylesReportContent>,
    required: true
  }
});

const DCPrimaryTraitTitle = ref<string>("");
const DCPrimaryTraitBody = ref<string>("");
const DCSecondaryTraitTitle = ref<string>("");
const DCSecondaryTraitBody = ref<string>("");
const DCMainTitle = ref<string>("");

const DTPrimaryTraitTitle = ref<string>("");
const DTPrimaryTraitBody = ref<string>("");
const DTSecondaryTraitTitle = ref<string>("");
const DTSecondaryTraitBody = ref<string>("");
const DTMainTitle = ref<string>("");

const OWPrimaryTraitTitle = ref<string>("");
const OWPrimaryTraitBody = ref<string>("");
const OWSecondaryTraitTitle = ref<string>("");
const OWSecondaryTraitBody = ref<string>("");
const OWMainTitle = ref<string>("");

const SFPrimaryTraitTitle = ref<string>("");
const SFPrimaryTraitBody = ref<string>("");
const SFSecondaryTraitTitle = ref<string>("");
const SFSecondaryTraitBody = ref<string>("");
const SFMainTitle = ref<string>("");

const DCFactors = ref(props.reportContent?.narratives.DC.factors);
const DTFactors = ref(props.reportContent?.narratives.DT.factors);
const OWFactors = ref(props.reportContent?.narratives.OW.factors);
const SFFactors = ref(props.reportContent?.narratives.SF.factors);

if (props.reportContent) {
  if (DCFactors.value && typeof DCFactors.value === "object") {
    DCPrimaryTraitTitle.value = DCFactors.value.primary_trait.title;
    DCPrimaryTraitBody.value = DCFactors.value.primary_trait.definition[0].children[0].text;
    DCSecondaryTraitTitle.value = DCFactors.value.secondary_trait.title;
    DCSecondaryTraitBody.value = DCFactors.value.secondary_trait.definition[0].children[0].text;
    DCMainTitle.value = DCSecondaryTraitTitle.value + " " + t("common.vs") + " " + DCPrimaryTraitTitle.value;
  }
  if (DTFactors.value && typeof DTFactors.value === "object") {
    DTPrimaryTraitTitle.value = DTFactors.value.primary_trait.title;
    DTPrimaryTraitBody.value = DTFactors.value.primary_trait.definition[0].children[0].text;
    DTSecondaryTraitTitle.value = DTFactors.value.secondary_trait.title;
    DTSecondaryTraitBody.value = DTFactors.value.secondary_trait.definition[0].children[0].text;
    DTMainTitle.value = DTSecondaryTraitTitle.value + " " + t("common.vs") + " " + DTPrimaryTraitTitle.value;
  }
  if (OWFactors.value && typeof DTFactors.value === "object") {
    OWPrimaryTraitTitle.value = OWFactors.value.primary_trait.title;
    OWPrimaryTraitBody.value = OWFactors.value.primary_trait.definition[0].children[0].text;
    OWSecondaryTraitTitle.value = OWFactors.value.secondary_trait.title;
    OWSecondaryTraitBody.value = OWFactors.value.secondary_trait.definition[0].children[0].text;
    OWMainTitle.value = OWSecondaryTraitTitle.value + " " + t("common.vs") + " " + OWPrimaryTraitTitle.value;
  }
  if (SFFactors.value && typeof DTFactors.value === "object") {
    SFPrimaryTraitTitle.value = SFFactors.value.primary_trait.title;
    SFPrimaryTraitBody.value = SFFactors.value.primary_trait.definition[0].children[0].text;
    SFSecondaryTraitTitle.value = SFFactors.value.secondary_trait.title;
    SFSecondaryTraitBody.value = SFFactors.value.secondary_trait.definition[0].children[0].text;
    SFMainTitle.value = SFSecondaryTraitTitle.value + " " + t("common.vs") + " " + SFPrimaryTraitTitle.value;
  }
} else {
  store.snackbarVisible = true;
  store.snackbarMessage = t("translations.common.no_data_message");
}
</script>

<style scoped>

</style>

<template>
  <v-card>
    <v-card-title>
      {{ $t('components.ChangeUserRole.title') }}
    </v-card-title>
    <v-card-subtitle>{{ $t("components.ChangeUserRole.subtitle") }}</v-card-subtitle>
    <v-card-text>
      <v-select
        v-model="userRole"
        :items="roles"
        item-title="title"
        item-value="value"
        variant="outlined" density="compact"
        :label="$t(`roles.${userRole}`)"
        no-data-text="User Roles"
        @update:model-value="handleRoleSelect"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn-secondary
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn-secondary>
      <v-btn-primary
        @click="changeRole"
      >
        {{ $t("buttons.confirm") }}
      </v-btn-primary>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {PropType, ref} from "vue";
import {isYapiError, DashUser, Role, YapiError} from "@YenzaCT/sdk";

import {useGlobalStore} from "@/store";
import yapi from "@/lib/yapi";
import {useI18n} from "vue-i18n";

const { t } = useI18n();
const store = useGlobalStore();

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  }
});
const userRole = ref(props.user.app.role);

const roles = ref(Object.values(Role)
  .map((slug) => ({
    title: t(`roles.${slug}`),
    value: slug
  })));

function handleRoleSelect(selectedRole: Role) {
  userRole.value = selectedRole;
}

const emit = defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
  (e: "roleUpdate", role: Role): void;
}
>();

const changeRole = async () => {
  try {
    if (userRole.value && props.user._id) {
      await yapi.admin.user.edit(props.user._id, {app: {role: userRole.value}} as DashUser);
      emit("roleUpdate", userRole.value);
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    emit("close");
  }
};
</script>

import {createVuetify, ThemeDefinition} from "vuetify";
import {aliases, fa} from "vuetify/iconsets/fa";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@fortawesome/fontawesome-pro/css/all.css";
import { getCookie } from "typescript-cookie";
import "@/styles/main.scss";
import {VBtn} from "vuetify/components/VBtn";

export default (light: ThemeDefinition, dark: ThemeDefinition) => createVuetify({
  components: {
    ...components,
  },
  directives,
  icons: {
    defaultSet: "fa",
    aliases,
    sets: {
      fa
    }
  },
  theme: {
    defaultTheme: getCookie("theme") || "light",
    themes: {
      light,
      dark
    }
  },
  aliases: {
    VBtnPrimary: VBtn,
    VBtnSecondary: VBtn,
    VBtnDelete: VBtn,
  },

  defaults: {
    VBtnPrimary: {
      style: { "border-radius": "12px", "min-width": "160px" },
      class: ["v-btn--primary", "text-white", "mr-2"],
      color: "primary"
    },
    VBtnSecondary: {
      style: { "border-radius": "12px", "min-width": "160px" },
      class: [
        "v-btn--secondary",
        "text-primary",
        "bg-grey-lighten-4",
        "bg-grey-lighten-4",
        "mr-2"
      ],
      variant: "outlined"
    },
    VBtnDelete: {
      style: { "border-radius": "12px", "min-width": "160px" },
      color: "red",
      class: ["bg-red-darken-2", "text-white"]
    },
    VCheckboxBtn: {
      color: "primary"
    }
  },
});

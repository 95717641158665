<template>
  <v-form>
    <v-card class="ma-2 pa-2 text-center">
      <v-card-title>
        {{ $t("components.ExportAccessCodes.title") }}
      </v-card-title>
      <v-divider/>
      <v-card-text>

        <div class="text-left text-body-2">
          <div>
            <b>{{ $t('components.ExportAccessCodes.active_filters') }}</b>
            <template v-for="column in cols">
              <v-chip
                :key="`${column.modelKey}_export_filter`"
                class="ml-2"
                size="small"
                v-if="column.filter && column.filterText">{{ column.title }}: <b> {{ column.filterText }}</b></v-chip>
            </template>
          </div>

          <div>
            <b>{{ $t('components.ExportAccessCodes.format') }}: </b> {{ $t(`common.${format}`) }}
          </div>
        </div>

        <div class="mt-6 bg-grey-lighten-3 pa-2">
          {{ $t("components.ExportAccessCodes.subtitle") }}
          <v-checkbox-btn
            v-for="option in exportOptions"
            :key="option.key"
            v-model="selected[option.key]"
            :data-pw="`checkbox_${option.key}`"
            :label="option.title"></v-checkbox-btn>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn-secondary
          @click="$emit('close')"
        >
          {{ $t('buttons.cancel') }}
        </v-btn-secondary>
        <v-btn-primary
          data-pw="confirmExportButton"
          @click="exportCSV"
        >
          {{ $t('buttons.confirm') }}
        </v-btn-primary>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script setup lang="ts">
import {computed, ComputedRef, ref, PropType} from "vue";
import {useI18n} from "vue-i18n";
import {ColumnsConfig} from "@/lib/pagination";
import {AccessCodeExportSelectors, Role} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";

const store = useGlobalStore();
const {t} = useI18n();
const selected = ref<AccessCodeExportSelectors>({});
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);

defineProps({
  cols: {
    type: Object as PropType<ColumnsConfig>,
    required: true
  },
  format: {
    type: String,
    required: true
  }
});

selected.value = {
  accountType: true,
  code: true,
  cohort: true,
  createdAt: true,
  institution: true,
  research: true,
  tenant: true,
  usedAt: true,
  user: true,
};

const emit = defineEmits<{
  (e: "download", selected: AccessCodeExportSelectors): void;
  (e: "close"): void;
}>();

const exportOptions: ComputedRef<{ title: string, key: string }[]> = computed(() => {
  const options = [
    {
      title: t("accessCode.code"),
      key: "code",
    },
    {
      title: t("accessCode.account_type"),
      key: "accountType",
    },
    {
      title: t("accessCode.tenant"),
      key: "tenant",
    },
    {
      title: t("accessCode.institution"),
      key: "institution",
    },
    {
      title: t("accessCode.cohort"),
      key: "cohort",
    },
    {
      title: t("accessCode.used_at"),
      key: "usedAt",
    },
    {
      title: t("common.created_at"),
      key: "createdAt",
    },
    {
      title: t("accessCode.user"),
      key: "user",
    },
  ];

  if (isAdminOrRoot.value) options.push({
    title: t("accessCode.research"),
    key: "research",
  });

  return options;
});

async function exportCSV() {
  emit("download", selected.value);
}

</script>

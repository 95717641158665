<template>
  <v-card>
    <v-card-title>
      {{ $t('components.ChangeAccountTypeDialog.title') }}
    </v-card-title>

    <v-card-text>
      {{ $t('components.ChangeAccountTypeDialog.label') }}
      <span class="font-weight-bold">
        {{ $t(`accountTypes.${accountType}`) }}
      </span>

      <v-select
        v-model="accountType"
        :items="accountTypes"
        item-title="title"
        item-value="value"
        variant="outlined" density="compact"
        :label="$t('components.ChangeAccountTypeDialog.label')"
        :no-data-text="$t('components.ChangeAccountTypeDialog.label')"
        @update:model-value="handleAccountTypeSelect"
      ></v-select>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn-secondary
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn-secondary>
      <v-btn-primary
        @click="changeAccountType"
      >
        {{ $t("buttons.confirm") }}
      </v-btn-primary>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {PropType, ref} from "vue";
import {isYapiError, DashUser, AccountType, YapiError} from "@YenzaCT/sdk";
import {useI18n} from "vue-i18n";
import {useGlobalStore} from "@/store";
import yapi from "@/lib/yapi";

const store = useGlobalStore();
const {t} = useI18n();

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  }
});

const accountType = ref(props.user.app.accountType);

const accountTypes = ref(Object.values(AccountType)
  .map((slug) => ({
    title: t(`accountTypes.${slug}`),
    value: slug
  })));

function handleAccountTypeSelect(selectedAccountType: AccountType) {
  accountType.value = selectedAccountType;
}

const emit = defineEmits<{
(e: "confirm"): void;
(e: "close"): void;
}
>();

const changeAccountType = async () => {
  try {
    if (accountType.value && props.user._id) {
      await yapi.admin.user.edit(props.user._id, {app: { accountType: accountType.value }} as DashUser);
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    emit("close");
  }
};
</script>

<template>
  <v-row v-if="assessmentComplete" class="d-flex flex-column">
    <div class="flex-grow-1">
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <trait-score-card
              :title="$t('assessments.maze.title')"
              :s-v-g-icon="builder(50)"
              :score="maze.total.total_score! * 100"
              colour="purple"
              :info="$t('assessments.maze.info')"
            />
          </v-col>
          <v-col cols="12">
            <trait-score-card
              title="Rock, Paper, Scissors Assessment"
              :s-v-g-icon="influencer(50)"
              :score="rockPaperScissors.total.total_score! * 100"
              colour="blue"
              :info="$t('assessments.maze.info')"
            />
          </v-col>
        </v-row>
      </v-col>
    </div>
    <v-dialog cols="12" v-model="dialogOpenDetail" transition="dialog-bottom-transition" >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn dark @click="closeDetailedDialog">
            <v-icon>fa fa-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t("assessments.cognitive_ability.title") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-col cols="12">
            {{  $t("common.coming_soon_label")}}
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
  <v-row v-else-if="maze">
    <v-col cols="6">
      <trait-score-card
        :title="$t('assessments.maze.title')"
        :s-v-g-icon="builder(50)"
        :score="maze.total.total_score! * 100"
        colour="purple"
        :info="$t('assessments.maze.info')"
      />
    </v-col>
    <v-col cols="6">
      <h2>{{ $t("assessments.assessment_not_done") }}</h2>
    </v-col>
  </v-row>
  <v-row v-else-if="!rockPaperScissors">
    <v-col>
      <h2>{{ $t("assessments.assessment_not_done") }}</h2>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">

import {builder, influencer} from "@/assets/reportSvgs";
import TraitScoreCard from "@/pages/UserDetailPage/components/common/TraitScoreCard.vue";
import { MazeResult, RockPaperScissorsResult } from "@YenzaCT/sdk";
import {PropType, ref, watch} from "vue";
import {useI18n} from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
  maze: {
    type: Object as PropType<MazeResult>,
    required: true
  },
  rockPaperScissors: {
    type: Object as PropType<RockPaperScissorsResult>,
    required: true
  },
  dialogOpen: {
    type: Boolean,
    default: false,
    required: true
  },
});

const dialogOpenDetail = ref(props.dialogOpen);

watch(() => props.dialogOpen, (newVal) => {
  dialogOpenDetail.value = newVal;
});

function closeDetailedDialog() {
  dialogOpenDetail.value = false;
}

let calculatedMazeRating = "";
const mazeTotalScore = props.maze.total.total_score as number * 10;

function calculateMazeRating() {
  if (mazeTotalScore <= 2.6) {
    calculatedMazeRating = t("components.CognitiveAbilityReport.very_low");
    return calculatedMazeRating;
  } else if (mazeTotalScore <= 4.6) {
    calculatedMazeRating = t("components.CognitiveAbilityReport.low");
    return calculatedMazeRating;
  } else if (mazeTotalScore <= 5.6) {
    calculatedMazeRating = t("components.CognitiveAbilityReport.medium");
    return calculatedMazeRating;
  } else if (mazeTotalScore <= 7.6) {
    calculatedMazeRating = t("components.CognitiveAbilityReport.high");
    return calculatedMazeRating;
  } else if (mazeTotalScore <= 10) {
    calculatedMazeRating = t("components.CognitiveAbilityReport.very_high");
    return calculatedMazeRating;
  }
}
calculateMazeRating();

let calculatedRockPaperScissorsRating = "";
const rockPaperScissorsTotalScore = props.rockPaperScissors.total.total_score as number * 10;
function calculateRockPaperScissorsRating() {
  if (rockPaperScissorsTotalScore <= 2.6) {
    calculatedRockPaperScissorsRating = t("components.CognitiveAbilityReport.very_low");
    return calculatedRockPaperScissorsRating;
  } else if (rockPaperScissorsTotalScore <= 4.6) {
    calculatedRockPaperScissorsRating = t("components.CognitiveAbilityReport.low");
    return calculatedRockPaperScissorsRating;
  } else if (rockPaperScissorsTotalScore <= 5.6) {
    calculatedRockPaperScissorsRating = t("components.CognitiveAbilityReport.medium");
    return calculatedRockPaperScissorsRating;
  } else if (rockPaperScissorsTotalScore <= 7.6) {
    calculatedRockPaperScissorsRating = t("components.CognitiveAbilityReport.high");
    return calculatedRockPaperScissorsRating;
  } else if (rockPaperScissorsTotalScore <= 10) {
    calculatedRockPaperScissorsRating = t("components.CognitiveAbilityReport.very_high");
    return calculatedRockPaperScissorsRating;
  }
}
calculateRockPaperScissorsRating();
const assessmentComplete = props.maze.total.total_score && props.rockPaperScissors.total.total_score;
</script>

<style scoped>
.btn-bg {
  background-color: #F1F5F9;
  border-color: #CBD5E1;
}
</style>

<template>
  <authenticated-layout>
    <template #headerIcon>
      far fa-ticket
    </template>
    <template #header>
      {{ $t("components.AccessCodeTablePage.title") }}
    </template>

    <v-data-table-server
      class="mb-6"
      density="compact"
      :loading="store.networkBusy"
      :headers="headers"
      :items="accessCodes"
      :items-per-page="perPage"
      hide-default-footer
      data-pw="accessCodeTable"
      @update:sort-by="onSort"
      :items-length="0"
      @click:row="onClickRow"
      :sort-by="sort"
      :hover="true"
    >
      <template v-slot:no-data>
        <h5 class="pa-2 d-flex align-center justify-center align-items-center">
          {{ $t("components.AccessCodeTablePage.no_data_message") }}
        </h5>
      </template>
      <template v-slot:loader>
        <v-sheet
          class="d-flex align-center justify-center"
          height="100%"
        >
          <v-progress-linear
            indeterminate
            color="primary"
          ></v-progress-linear>
        </v-sheet>
      </template>

      <template v-slot:top>
        <v-toolbar class="data-table-toolbar">
          <search-text-field
            class="ml-2"
            :label="$t('components.AccessCodeTablePage.search')"
            data-pw="codesSearchBox"
            @update:text="text => columns.code.filter = text"
            @change="columns.code.filterText = columns.code.filter as string"
          />
          <v-spacer/>
          <toolbar-menu
            :label="$t('common.filters')"
            :active-filter-count="activeFilterCount"
            icon="far fa-filter"
            @resetFilter="resetFilter"
          >
            <toolbar-menu-list-item-menu
              :label="columns.code.title"
              :is-filter-active="!!columns.code.filter"
            >
              <filter-text-field
                :label="columns.code.title"
                v-model="codeFilter"
                @change="columns.code.filterText = columns.code.filter as string"
              />
            </toolbar-menu-list-item-menu>
            <toolbar-menu-list-item-menu
              :label="columns.accountType.title"
              :is-filter-active="!!columns.accountType.filter"
            >
              <select-account-type
                :label="columns.accountType.title"
                v-model="columns.accountType.filter"
                @update:modelValue="columns.accountType.filterText = t(`accountTypes.${columns.accountType.filter}`)"
              />
            </toolbar-menu-list-item-menu>
            <toolbar-menu-list-item-menu
              v-if="isAdminOrRoot"
              :label="columns.research.title"
              :is-filter-active="!!columns.research.filter"
            >
              <v-radio-group
                :disabled="store.networkBusy"
                v-model="columns.research.filter"
                data-pw="researchFilter"
              >
                <v-radio
                  :label="$t('common.yes')"
                  value="true"
                  data-pw="researchFilterYes"
                  @change="columns.research.filterText = 'Yes'"
                />
                <v-radio
                  :label="$t('common.no')"
                  value="false"
                  data-pw="researchFilterNo"
                  @change="columns.research.filterText = 'No'"
                />
                <v-radio
                  :label="$t('common.all')"
                  value="all"
                  data-pw="researchFilterAll"
                  @change="columns.research.filterText = 'All'"
                />
              </v-radio-group>
            </toolbar-menu-list-item-menu>
            <toolbar-menu-list-item-menu
              :label="columns.used.title"
              :is-filter-active="!!columns.used.filter"
            >
              <v-radio-group
                :disabled="store.networkBusy"
                v-model="columns.used.filter"
                data-pw="usedAtFilter"
              >
                <v-radio
                  :label="$t('common.yes')"
                  value="true"
                  data-pw="usedAtFilterYes"
                  @change="columns.used.filterText = 'Yes'"
                />
                <v-radio
                  :label="$t('common.no')"
                  value="false"
                  data-pw="usedAtFilterNo"
                  @change="columns.used.filterText = 'No'"
                />
                <v-radio
                  :label="$t('common.all')"
                  value="all"
                  data-pw="usedAtFilterAll"
                  @change="columns.used.filterText = 'All'"
                />
              </v-radio-group>
            </toolbar-menu-list-item-menu>
            <toolbar-menu-list-item-menu
              :label="$t('common.segment')"
              :is-filter-active="!!columns.tenant.filter">
              <tenant-search-dropdown v-model="selectedTenant"
              />
              <tenant-institution-single-select-autocomplete
                v-model="selectedInstitution"
                :tenant="selectedTenant"
              />
              <cohort-search-dropdown
                v-model="selectedCohort"
                :institution="selectedInstitution"
                :tenant="selectedTenant"
              />
            </toolbar-menu-list-item-menu>
            <toolbar-menu-list-item-menu
              v-model="createdAtDateFilterMenu"
              :label="columns.createdAt.title"
              :is-filter-active="columns.createdAt.filter.length > 0"
            >
              <vue-date-picker
                v-model="createdAtDateFilter"
                range
                inline
                auto-apply
                multi-calendars
                :enable-time-picker="false"
              />
            </toolbar-menu-list-item-menu>
          </toolbar-menu>

          <toolbar-menu :label="$t('common.columns')" icon="far fa-columns">
            <v-list-item>
              <v-checkbox-btn
                :label="$t('accessCode.id')"
                v-model="columns.id.visible"
                :color="columns.id.visible ? 'primary' : ''"
                data-pw="voucherIdColumn"
              />
            </v-list-item>
            <v-list-item>
              <v-checkbox-btn
                :label="$t('accessCode.code')"
                v-model="columns.code.visible"
                :color="columns.code.visible ? 'primary' : ''"
                data-pw="voucherCodeColumn"
              />
            </v-list-item>
            <v-list-item>
              <v-checkbox-btn
                :label="$t('accessCode.user')"
                v-model="columns.user.visible"
                :color="columns.user.visible ? 'primary' : ''"
                data-pw="voucherUserColumn"
              />
            </v-list-item>
            <v-list-item>
              <v-checkbox-btn
                :label="$t('accessCode.account_type')"
                v-model="columns.accountType.visible"
                :color="columns.accountType.visible ? 'primary' : ''"
                data-pw="voucherAccountTypeColumn"
              />
            </v-list-item>
            <v-list-item v-if="isAdminOrRoot">
              <v-checkbox-btn
                :label="$t('accessCode.research')"
                v-model="columns.research.visible"
                :color="columns.research.visible ? 'primary' : ''"
                data-pw="voucherResearchColumn"
              />
            </v-list-item>
            <v-list-item>
              <v-checkbox-btn
                :label="$t('accessCode.used_at')"
                v-model="columns.usedAt.visible"
                :color="columns.usedAt.visible ? 'primary' : ''"
                data-pw="voucherUsedAtColumn"
              />
            </v-list-item>
            <v-list-item>
              <v-checkbox-btn
                :label="$t('common.created_at')"
                v-model="columns.createdAt.visible"
                :color="columns.createdAt.visible ? 'primary' : ''"
                data-pw="voucherCreatedAtColumn"
              />
            </v-list-item>
          </toolbar-menu>

          <data-export-download-menu :formats="['csv', 'excel']" class="mr-4" @click="handleExportClick"/>

          <v-btn-primary
            v-if="isAdminOrRoot"
            prepend-icon="far fa-plus"
            @click="showCreateVoucher = true"
            data-pw="createVoucherButton"
          >
            {{ $t("buttons.create") }}
          </v-btn-primary>
        </v-toolbar>
        <v-sheet v-if="activeFilterCount > 0" class="pa-2">
          <b>{{ $t("common.filters")}}</b>
          <template v-for="column in columns">
            <v-chip
              :key="`${column.modelKey}_filter`"
              class="ml-2"
              size="small"
              v-if="column.filter && column.filterText">{{ column.title }}: <b>
                {{ column.filterText }}</b></v-chip>
          </template>
          <v-divider class="mt-2"/>
        </v-sheet>
      </template>

      <template v-slot:item.accountType="{ item }">
        {{ $t(`accountTypes.${item["accountType"]}`) }}
      </template>

      <template v-slot:item.user="{ item }">
        <div v-if="item.user">
          <v-chip size="x-small" v-if="(item.user as DashUser).email">{{ (item["user"] as DashUser).email }}</v-chip>
          <v-chip size="x-small" v-if="(item.user as DashUser).phone">{{ (item["user"] as DashUser).phone }}</v-chip>
          <v-chip size="x-small" v-if="(item.user as DashUser).profile.firstName || (item.user as DashUser).profile.lastName ">
            <span v-if="(item.user as DashUser).profile.firstName">{{ (item["user"] as DashUser).profile.firstName }}</span>
            <span v-if="(item.user as DashUser).profile.lastName">{{ (item["user"] as DashUser).profile.lastName }}</span>
          </v-chip>
        </div>
        <div v-else>-</div>
      </template>

      <template v-slot:item.research="{ item }">
        <v-icon
          v-if="item['research']"
          size="x-small"
        >
          far fa-check
        </v-icon>
      </template>

      <template v-slot:item.usedAt="{ item }">
        <v-icon color="red" v-if="!item.usedAt">far fa-xmark fa-regular</v-icon>
        <v-icon color="green" v-else>far fa-check</v-icon>
        <v-chip v-if="item.usedAt" size="x-small">{{ toLocaleDateTime(item["usedAt"]) }}</v-chip>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ toLocaleDateTime(item["createdAt"]) }}
      </template>

      <template v-slot:bottom>
        <v-divider/>
        <pagination-controls
          v-if="paginatedData"
          class="text-center pt-2"
          :pagination-footer-data="paginationFooterData"
          @update:per-page="onUpdatePerPage"
          @on-next="onNext"
          @on-prev="onPrev"
          @on-page-change="onPageChange"
          data-pw="accessCodePaginationControls"
        />
      </template>
    </v-data-table-server>
    <v-dialog
      v-model="showExportDialog"
      max-width="500"
    >
      <export-voucher
        @close="showExportDialog = false"
        @download="exportPages"
        :format="exportFormat"
        :cols="cols"
      >
      </export-voucher>

    </v-dialog>
    <v-dialog v-model="showCreateVoucher" :persistent="true" max-width="500">
      <create-voucher-dialog
        @close="onCloseCreateDialog"
        :title="t('components.AccessCodeTablePage.create_voucher')"
        :user-choice=true
      />
    </v-dialog>

    <v-snackbar v-model="showExportingSnackBar">
      Your export is downloading.
      <v-progress-linear :indeterminate="true"/>
    </v-snackbar>
  </authenticated-layout>
</template>

<script setup lang="ts">
import {onMounted, ref, Ref, computed, watch} from "vue";
import yapi from "@/lib/yapi";
import {useI18n} from "vue-i18n";
import {useRouter, useRoute} from "vue-router";
import {toLocaleDateTime} from "@/lib/time";
import {
  AccessCode,
  AccessCodeExportSelectors,
  AccessCodePaginationParams,
  AccountType,
  isYapiError,
  Paginated,
  Role,
  Tenant,
  Institution,
  Cohort,
  YapiError, DashUser
} from "@YenzaCT/sdk";

import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";
import ExportVoucher from "@/pages/AccessCodeTablePage/ExportAccessCodes.vue";
import PaginationControls from "@/components/PaginationControls.vue";
import SearchTextField from "@/components/SearchTextField.vue";
import {useGlobalStore} from "@/store";
import CreateVoucherDialog from "@/components/Voucher/CreateVoucherDialog.vue";
import {ColumnsConfig, FooterData, footerDataFactory, usePagination} from "@/lib/pagination";
import FilterTextField from "@/components/DataTable/FilterTextField.vue";
import ToolbarMenuListItemMenu from "@/components/DataTable/ToolbarMenuListItemMenu.vue";
import ToolbarMenu from "@/components/DataTable/ToolbarMenu.vue";
import SelectAccountType from "@/components/SelectAccountType.vue";
import TenantSearchDropdown from "@/components/FormFields/TenantSearchDropdown.vue";
import CohortSearchDropdown from "@/components/FormFields/CohortSearchDropdown.vue";
import TenantInstitutionSingleSelectAutocomplete
  from "@/components/FormFields/InstitutionSearchDropdown.vue";
import {WritableComputedRef} from "@vue/reactivity";
import {downloadExcel, downloadCSV} from "@/lib/fileDownload";
import {DateTime} from "luxon";
import VueDatePicker from "@vuepic/vue-datepicker";
import DataExportDownloadMenu from "@/components/DataExportDownloadMenu.vue";
const store = useGlobalStore();
const router = useRouter();
const route = useRoute();

const { t } = useI18n();

const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);
const showExportDialog = ref(false);
const exportFormat = ref("csv");
const showCreateVoucher = ref(false);
const accessCodes: Ref<AccessCode[]> = ref([]);
const paginatedData: Ref<Paginated<AccessCode> | undefined> = ref();
let params: AccessCodePaginationParams;
let paginationFooterData: FooterData = footerDataFactory();

const showExportingSnackBar = ref<boolean>(false);

const createdAtDateFilterMenu = ref();

const cols: ColumnsConfig = {
  id: {
    title: t("accessCode.id"),
    modelKey: "_id",
    visible: false,
    filter: "",
    filterText: ""
  },
  code: {
    title: t("accessCode.code"),
    modelKey: "code",
    visible: true,
    filter: "",
    filterText: ""
  },
  tenant: {
    title: t("accessCode.tenant"),
    modelKey: "tenant.title",
    visible: true,
    filter: "",
    filterText: "",
  },
  institution: {
    title: t("accessCode.institution"),
    modelKey: "institution.title",
    visible: true,
    filter: "",
    filterText: ""
  },
  cohort: {
    title: t("accessCode.cohort"),
    modelKey: "cohort.title",
    visible: true,
    filter: ""
  },
  user: {
    title: t("accessCode.user"),
    modelKey: "user",
    visible: true,
    filter: "",
    filterText: ""
  },
  accountType: {
    title: t("accessCode.account_type"),
    modelKey: "accountType",
    visible: true,
    filter: "",
    filterText: ""
  },
  research: {
    title: t("accessCode.research"),
    modelKey: "research",
    visible: false,
    filter: "",
    filterText: ""
  },
  createdAt: {
    title: t("common.created_at"),
    modelKey: "createdAt",
    visible: true,
    filter: "",
    filterText: ""
  },
  used: {
    title: t("accessCode.used"),
    modelKey: "usedAt",
    visible: false,
    filter: "",
    filterText: ""
  },
  usedAt: {
    title: t("accessCode.used_at"),
    modelKey: "usedAt",
    visible: true,
    filter: "",
    filterText: ""
  }
};

const {
  headers,
  page,
  perPage,
  sort,
  activeFilterCount,
  selectedTenant,
  selectedInstitution,
  selectedCohort,
  createdAtDateFilter,
  setupTenantFilters,
  watchDateFilter,
  resetFilter,
  createUrlFilterParameters,
  onUpdatePerPage,
  onSort,
  onNext,
  onPrev,
  columns,
  onPageChange,
  setupFooterData,
} = usePagination(fetchPage, {
  initialColumns: cols,
  initialSort: [{
    key: "createdAt",
    order: "desc"
  }]
});

onMounted(async () => {
  setupParams();
  await setupTenantFilters(
    columns.tenant.filter as string,
    columns.institution.filter as string,
    columns.cohort.filter as string
  );
  await fetchPage();
});

const codeFilter: WritableComputedRef<string> = computed({
  get: (): string => columns.code.filter.toString(),
  set: (val: string | string[]) => {
    columns.code.filter = val.toString();
  }
});

watch(() => selectedTenant.value, (newTenant: Tenant | undefined) => {
  columns.tenant.filter = "";
  columns.tenant.filterText = "";

  if (newTenant && newTenant._id) {
    columns.tenant.filter = newTenant._id;
    columns.tenant.filterText = newTenant.title;
  }
});

watch(() => selectedInstitution.value, (newInstitution: Institution | undefined) => {
  columns.institution.filter = "";
  columns.institution.filterText = "";

  if (newInstitution && newInstitution._id) {
    columns.institution.filter = newInstitution._id;
    columns.institution.filterText = newInstitution.title;
  }
});

watch(() => selectedCohort.value, (newCohort: Cohort | undefined) => {
  columns.cohort.filter = "";
  columns.cohort.filterText = "";

  if (newCohort && newCohort._id) {
    columns.cohort.filter = newCohort._id;
    columns.cohort.filterText = newCohort.title;
  }
});

watchDateFilter(createdAtDateFilter, columns.createdAt);

function handleExportClick(format: string) {
  showExportDialog.value = true;
  exportFormat.value = format;
}

function setupParams() {
  page.value = route.query.page ? parseInt(route.query.page as string) : page.value;
  perPage.value = route.query.size ? parseInt(route.query.size as string) : perPage.value;
  sort.value[0].key = route.query.sortBy as string || sort.value[0].key;
  sort.value[0].order = route.query.sortOrder as "asc" | "desc" || sort.value[0].order;

  columns.code.filter = route.query.filterCode as string || columns.code.filter;
  columns.accountType.filter = route.query.filterAccountType as AccountType || columns.accountType.filter;

  columns.tenant.filter = route.query.filterTenant as string || columns.tenant.filter;
  columns.institution.filter = route.query.filterInstitution as string || columns.institution.filter;
  columns.cohort.filter = route.query.filterCohort as string || columns.cohort.filter;
}

function setupDateRangeFromFilterParams(initialParams: AccessCodePaginationParams): AccessCodePaginationParams {
  const params: AccessCodePaginationParams = {...initialParams};

  params.filterCreatedAtStart = DateTime.fromISO(columns.createdAt.filter[0]).startOf("day").toISO() as string || undefined;
  params.filterCreatedAtEnd = DateTime.fromISO(columns.createdAt.filter[1]).endOf("day").toISO() as string || undefined;

  return params;
}

async function exportPages(selected: AccessCodeExportSelectors) {
  // Close the dialog
  showExportDialog.value = false;

  const params = setupDateRangeFromFilterParams({});

  if (cols.cohort.filter) {
    params.filterCohort = cols.cohort.filter as string;
  }

  if (cols.tenant.filter) {
    params.filterTenant = cols.tenant.filter as string;
  }

  if (cols.institution.filter) {
    params.filterInstitution = cols.institution.filter as string;
  }

  if (cols.code.filter) {
    params.filterCode = cols.code.filter as string;
  }

  if (cols.accountType.filter) {
    params.filterAccountType = cols.accountType.filter as AccountType;
  }

  if (cols.used.filter === "true") {
    params.filterUsed = true;
  } else if (cols.used.filter === "false") {
    params.filterUsed = false;
  }

  if (cols.research.filter === "true") {
    params.filterResearch = true;
  } else if (cols.research.filter === "false") {
    params.filterResearch = false;
  }

  if (exportFormat.value === "excel")
    params.format = "excel";
  else params.format = "csv";

  try {
    store.networkBusy = true;
    showExportingSnackBar.value = true;
    const res = await yapi.admin.accessCode.export({selectors: selected}, params);

    const timestamp = DateTime.now().toFormat("yyyy-MM-dd HH-mm-ss");

    if (exportFormat.value === "excel") {
      downloadExcel(res.data.content.toString(), `vouchers ${timestamp}`);
    } else {
      downloadCSV(res.data.content.toString(), `vouchers ${timestamp}`);
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    showExportingSnackBar.value = false;
  }
}

async function fetchPage() {
  params = {
    page: page.value,
    size: perPage.value,
    sortBy: sort.value[0].key,
    sortOrder: sort.value[0].order,

    filterCode: columns.code.filter as string || undefined,
    filterAccountType: columns.accountType.filter as AccountType || undefined,
    filterTenant: columns.tenant.filter as string || undefined,
    filterInstitution: columns.institution.filter as string || undefined,
    filterCohort: columns.cohort.filter as string || undefined,
    filterCreatedAtStart: DateTime.fromISO(columns.createdAt.filter[0]).startOf("day").toISO() as string || undefined,
    filterCreatedAtEnd: DateTime.fromISO(columns.createdAt.filter[1]).endOf("day").toISO() as string || undefined,
  };

  if (cols.used.filter === "true") {
    params.filterUsed = true;
  } else if (cols.used.filter === "false") {
    params.filterUsed = false;
  }

  if (cols.research.filter === "true") {
    params.filterResearch = true;
  } else if (cols.research.filter === "false") {
    params.filterResearch = false;
  }

  try {
    store.networkBusy = true;
    paginatedData.value = (await yapi.admin.accessCode.paginate(params)).data;
    await router.push({query: createUrlFilterParameters(params)});
    const footerData = Object.assign(paginatedData.value);
    footerData.totalPages = paginatedData.value?.hasNextPage
      ? paginatedData.value.page + 1
      : paginatedData.value.page;
    paginationFooterData = setupFooterData(footerData);
    if (paginatedData.value)
      accessCodes.value = paginatedData.value.docs;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

async function onCloseCreateDialog() {
  showCreateVoucher.value = false;
  await fetchPage();
}

const onClickRow = async (event: Event, row: { internalItem: { raw?: AccessCode } }) => {
  try {
    if (row.internalItem && row.internalItem.raw) {
      const accessCodeId = row.internalItem.raw._id;
      if (accessCodeId) {
        await openAccessCode(accessCodeId);
      }
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  }
};

const openAccessCode = async (id: string) =>
  await router.push({
    name: "accessCode",
    params: {id}
  });
</script>

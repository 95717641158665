<template>
  <v-card :title="title" data-pw="disableEnableUserDialogCard">
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            {{ $t('components.DisableEnableDialog.warning', { action: user.auth.accountDisabled ? "enable" : "disable" }) }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t('common.type_confirm_message') }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-text-field
            @keyup.enter="disableEnableUser"
            v-model="confirmString"
            variant="outlined"
            placeholder="CONFIRM"
            required
            data-pw="confirmDisableEnableTextField"
          />
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn-secondary
        data-pw="cancelDisableEnableButton"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn-secondary>
      <v-btn-delete
        @click="disableEnableUser"
        data-pw="confirmDisableEnableButton"
        :disabled="confirmString.toUpperCase() !== 'CONFIRM'"
      >
        {{ $t("buttons.confirm") }}
      </v-btn-delete>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {computed, PropType, ref, Ref} from "vue";
import {isYapiError, DashUser, YapiError} from "@YenzaCT/sdk";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";

import {useGlobalStore} from "@/store";
import yapi from "@/lib/yapi";

const {t} = useI18n();
const store = useGlobalStore();

const route = useRoute();

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  },
});

const confirmString = ref("");

// Create a copy of the user object
const user: Ref<DashUser> = ref({
  ...props.user
});

const userId: Ref<string> = ref("");
userId.value = route.params.id as string;

const emits = defineEmits<{
  (e: "close"): void;
}
>();

const title = computed(
  () => t(user.value?.auth.accountDisabled ? "components.DisableEnableDialog.enable_user" : "components.DisableEnableDialog.disable_user")
);

const disableEnableUser = async () => {
  try {
    store.networkBusy = true;
    const res = await (
      user.value.auth.accountDisabled
        ? yapi.admin.user.enable(userId.value)
        : yapi.admin.user.disable(userId.value)
    );
    user.value = res.data.user;
    emits("close");
    store.showSnackbar(t(user.value?.auth.accountDisabled ? "components.DisableEnableDialog.user_disabled" : "components.DisableEnableDialog.user_enabled"));
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
};

</script>

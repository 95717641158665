<template>
  <v-row>
    <v-col>
      <info-card
        :icon="organiser(iconSize)"
        :title="organiserTitle"
        :body="organiserBody"/>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <info-card
        :icon="builder(iconSize)"
        :title="builderTitle"
        :body="builderBody"/>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <info-card
        :icon="creator(iconSize)"
        :title="creatorTitle"
        :body="creatorBody" />
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <info-card
        :icon="influencer(iconSize)"
        :title="influencerTitle"
        :body="influencerBody"/>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <info-card
        :icon="thinker(iconSize)"
        :title="thinkerTitle"
        :body="thinkerBody"/>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <info-card
        :icon="helper((iconSize))"
        :title="helperTitle"
        :body="helperBody" />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import InfoCard from "@/pages/UserDetailPage/components/common/InfoCard.vue";
import {builder, creator, helper, influencer, organiser, thinker} from "@/assets/reportSvgs";
import {ref} from "vue";
import {Definitions} from "@YenzaCT/sdk";
import {PropType} from "vue";
import {useI18n} from "vue-i18n";
import {useGlobalStore} from "@/store";

const props = defineProps({
  definitions: {
    type: Array as PropType<Definitions[]>,
    required: true,
  },
});

const iconSize = 25;
const store = useGlobalStore();
const {t} = useI18n();

const thinkerTitle = ref<string>("");
const influencerTitle = ref<string>("");
const organiserTitle = ref<string>("");
const helperTitle = ref<string>("");
const builderTitle = ref<string>("");
const creatorTitle = ref<string>("");

const thinkerBody = ref<string>("");
const influencerBody = ref<string>("");
const organiserBody = ref<string>("");
const helperBody = ref<string>("");
const builderBody = ref<string>("");
const creatorBody = ref<string>("");

if (props.definitions) {
  props.definitions.forEach((item: Definitions) => {
    switch (item.code) {
    case "I":
      thinkerTitle.value = item.title;
      thinkerBody.value = item.short_description[0].children[0].text;
      break;
    case "E":
      influencerTitle.value = item.title;
      influencerBody.value = item.short_description[0].children[0].text;
      break;
    case "C":
      organiserTitle.value = item.title;
      organiserBody.value = item.short_description[0].children[0].text;
      break;
    case "S":
      helperTitle.value = item.title;
      helperBody.value = item.short_description[0].children[0].text;
      break;
    case "R":
      builderTitle.value = item.title;
      builderBody.value = item.short_description[0].children[0].text;
      break;
    case "A":
      creatorTitle.value = item.title;
      creatorBody.value = item.short_description[0].children[0].text;
      break;
    default:
      break;
    }
  });
} else {
  store.snackbarVisible = true;
  store.snackbarMessage = t("translations.common.no_data_message");
}
</script>

<style scoped>

</style>

<template>
  <v-row>
    <v-col>
      <info-card
        :title="openMindednessTitle"
        :body= "openMindednessBody" />
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <info-card
        :title="conscientiousnessTitle"
        :body= "conscientiousnessBody" />
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <info-card
        :title="extraversionTitle"
        :body= "extraversionBody" />
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <info-card
        :title="agreeablenessTitle"
        :body= "agreeablenessBody" />
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <info-card
        :title="emotionalStabilityTitle"
        :body= "emotionalStabilityBody" />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import InfoCard from "@/pages/UserDetailPage/components/common/InfoCard.vue";
import {useGlobalStore} from "@/store";
import {PersonalityTraitsReportFactors} from "@YenzaCT/sdk";
import {ref, PropType} from "vue";
import {useI18n} from "vue-i18n";

const props = defineProps({
  factors: {
    type: Object as PropType<PersonalityTraitsReportFactors>,
    required: true
  }
});
const store = useGlobalStore();
const {t} = useI18n();
const extraversionTitle = ref<string>("");
const agreeablenessTitle = ref<string>("");
const emotionalStabilityTitle = ref<string>("");
const conscientiousnessTitle = ref<string>("");
const openMindednessTitle = ref<string>("");

const extraversionBody = ref<string>("");
const agreeablenessBody = ref<string>("");
const emotionalStabilityBody = ref<string>("");
const conscientiousnessBody = ref<string>("");
const openMindednessBody = ref<string>("");
const personalityTraitsReportFactors = ref(props.factors as PersonalityTraitsReportFactors);

if (personalityTraitsReportFactors.value && typeof personalityTraitsReportFactors.value === "object") {
  extraversionTitle.value = personalityTraitsReportFactors.value.extraversion.title;
  extraversionBody.value = personalityTraitsReportFactors.value.extraversion.description[0].children[0].text;
  agreeablenessTitle.value = personalityTraitsReportFactors.value.agreeableness.title;
  agreeablenessBody.value = personalityTraitsReportFactors.value.agreeableness.description[0].children[0].text;
  emotionalStabilityTitle.value = personalityTraitsReportFactors.value.emotional_stability.title;
  emotionalStabilityBody.value = personalityTraitsReportFactors.value.emotional_stability.description[0].children[0].text;
  openMindednessTitle.value = personalityTraitsReportFactors.value.open_mindedness.title;
  openMindednessBody.value = personalityTraitsReportFactors.value.open_mindedness.description[0].children[0].text;
  conscientiousnessTitle.value = personalityTraitsReportFactors.value.conscientiousness.title;
  conscientiousnessBody.value = personalityTraitsReportFactors.value.conscientiousness.description[0].children[0].text;
} else {
  store.snackbarVisible = true;
  store.snackbarMessage = t("translations.common.no_data_message");
}
</script>

<style scoped>

</style>

